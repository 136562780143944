import React from 'react'
import { useState } from "react"
const AddTask = ({onAdd}) => {
  const [text, setText] = useState("")
  const [day, setDay] = useState("")
  const [remainder, setRemainder] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()

    if (!text) {
      alert("Add Task")
      return
    } 
    onAdd({text, day, remainder})

    setText("")
    setDay("")
    setRemainder(false)
  }

  return (
    <form className='add-form' onSubmit={onSubmit}>
      <div className="form-control">
        <label>Task</label>
        <input type="text" placeholder='Add Task' value={text} onChange={(e) => setText(e.target.value)}/>
      </div>

      <div className="form-control ">
        <label>Schedule(day & time)</label>
        <input type="text" placeholder='Add Refrence' value={day} onChange={(e) => setDay(e.target.value)}/>
      </div>

      <div className="form-control form-control-check">
        <label>Remainder</label>
        <input type="checkbox" checked={remainder} value={remainder} onChange={(e) => setRemainder(e.currentTarget.checked)}/>
      </div>

      <input type="submit" className='btn btn-block' value="Save Task" />
    </form>
  )
}

export default AddTask