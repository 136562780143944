import React from 'react'
import { useState } from "react"
import Header from './components/Header'
import Tasks from './components/Tasks'
import AddTask from './components/AddTask'

const App = () => {
  const [showAddTask, setshowAddTask] = useState(false)
  const [tasks, setTasks] = useState(
    [
      // 
    ]
  )
  const change = () => {
    setshowAddTask(!showAddTask)
  }
  const addTask = (task) => {
    const id = Math.floor(Math.random() * 10000) + 1
    const newTask = { id, ...task }
    setTasks([...tasks, newTask])
  }
  const deleteTask = (id) => {
    setTasks(tasks.filter((task) => task.id !== id))
  }
  const toggleRemainder = (id) => {
    setTasks(tasks.map((task) => task.id === id ? { ...task, remainder: !task.remainder } : task))
  }
  return (
    <div className="applicat">
      <Header title="Todo application" change={change} toggleAdd={showAddTask} />
      <div className="container-box">
        <div className="container">
          {tasks.length > 0 ? <Tasks tasks={tasks} onDelete={deleteTask} onToggle={toggleRemainder} /> : <div style={{ color: "red", fontSize: "20px" }}>No task.....</div>}
          {showAddTask && <AddTask onAdd={addTask} />}
        </div>
      </div>
    </div>
  )
}

export default App