import React from "react"
import Button from "./Button"

const Header = ({ title, change, toggleAdd }) => {
  const onClick = change
  const titled = {
    color: "green",
    fontSize: "20px"
  }
  return (
    <header className="header">
      <p style={titled}>{title}</p>
      <Button color={toggleAdd ? "red" : "green"} text={toggleAdd ? "Close" : "Open"} onClick={onClick}/>
    </header>
  )
}

export default Header