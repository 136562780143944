import React from 'react'
import { FaTimes } from "react-icons/fa"
const Tasc = ({ task, onDelete, onToggle }) => {
  const timesStyle = {
    color: "red"
  }
  return (
    <div className = {`task ${task.remainder ? "reminder" : ""}`} onDoubleClick={() => onToggle(task.id)}>
      <h3>{task.text}<FaTimes style={timesStyle} onClick={() => onDelete(task.id)}/> </h3> 
      <p>{task.day}</p>      
    </div>
  )
}

export default Tasc